'use client';

import { Client } from '@ensite/domain/lib/clients';
import { EnsiteStaticPage } from '@ensite/next/lib/static-page';
import { EnsiteInitialProps } from '@ensite/next/lib/server';

interface ClientBuilderPageProps {
  stateId: string;
  client: Client;
  pageProps: EnsiteInitialProps;
}

export default function ClientStaticPage({
  stateId,
  client,
  pageProps: { page, initialProps, category },
}: ClientBuilderPageProps) {
  return (
    <EnsiteStaticPage
      stateId={stateId}
      settings={client.settings}
      category={category}
      page={page}
      initialProps={initialProps}
    />
  );
}
